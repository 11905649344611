<template>
  <div class="card">
    <div class="container">
      <app-card>
        <h1 class="title card__title">Картка пацієнта</h1>
        <patient-info />
        <card-form />
        <buttons />
      </app-card>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import CardForm from "./CardClientForm.vue";
import PatientInfo from "./PatientInfo.vue";
import Buttons from "./Buttons.vue";
export default {
  components: { PatientInfo, CardForm, Buttons },
  computed: {
    ...mapState({
      patient: (state) => state.currentPatient,
    }),
  },
};
</script>

<style scoped lang="scss">
.card {
  margin-top: 17px;
  margin-bottom: 80px;
  &__title {
    margin-bottom: 26px;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 26px;
      margin-bottom: 22px;
    }
    @media (max-width: 374px) {
      font-size: 22px;
      margin-bottom: 18px;
    }
  }
}
</style>