<template>
  <div class="card__wrap">
    <form class="card__form" v-if="patient">
      <div
        class="card__form-item"
        v-for="title in newTitles"
        :key="title.label"
      >
        <span class="card__form-label">{{ title.label }}</span>
        <span class="card__form-value" v-if="title.id === 'phone_number'">{{
          patient[title.id] | VMask(mask)
        }}</span>
        <span class="card__form-value" v-else-if="title.id === 'lifestyle'">{{
          calcValue(patient.lifestyle)
        }}</span>
        <span class="card__form-value" v-else-if="title.id === 'smoke'">{{
          calcValue(patient.smoke)
        }}</span>
        <span class="card__form-value" v-else>{{ patient[title.id] }}</span>
      </div>
    </form>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      mask: "## (###)-###-##-##",
      newTitles: [],
      formTitles: [
        { label: "Номер телефону", id: "phone_number" },
        { label: "Електронна пошта", id: "email" },
        { label: "Зріст", id: "growth" },
        { label: "Вага", id: "weight" },
        { label: "Глюкоза", id: "glucose" },
        { label: "Спосіб життя", id: "lifestyle" },
        { label: "Куріння", id: "smoke" },
        { label: "Кількість сигарет", id: "count_cigarettes" },
      ],
      options: [
        { label: "Низький", id: "low" },
        { label: "Середній", id: "medium" },
        { label: "Активний", id: "high" },
        { label: "Лежачий", id: "recumbent" },
        { label: "Сигарети", id: "cigarettes" },
        { label: "Кальян", id: "hookah" },
        { label: "Вейп тощо", id: "vape_other" },
        { label: "Не палю", id: "no_smoke" },
      ],
    };
  },

  computed: {
    ...mapState({
      patient: (state) => state.currentPatient,
    }),
  },
  // filters: {
  //   phoneNumber(value) {
  //     return value + 1;
  //   },
  // },
  methods: {
    ...mapActions(["searchPatient"]),
    ...mapMutations(["setCurrentPatient"]),
    calcValue(v) {
      let a;
      this.options.map((el) => {
        if (el.id === v) {
          a = el.label;
          return;
        }
      });
      return a;
    },
  },
  created() {
    let obj = localStorage.getItem("patient");
    this.searchPatient(JSON.parse(obj)).then((resp) => {
      if (resp.data) {
        this.setCurrentPatient(resp.data);
        if (resp.data.smoke === "cigarettes") {
          for (let key in this.formTitles) {
            if (resp.data[this.formTitles[key].id]) {
              this.newTitles.push(this.formTitles[key]);
            }
          }
          return;
        } else {
          for (let key in this.formTitles) {
            if (this.formTitles[key].id !== "count_cigarettes") {
              if (resp.data[this.formTitles[key].id]) {
                this.newTitles.push(this.formTitles[key]);
              }
            }
          }
        }
      }
    });
  },
};
</script>
<style scoped lang="scss">
.card {
  &__wrap {
    max-width: 1190px;
    padding: 0 10px;
    margin: 0 auto;
    @media (max-width: 1240px) {
      max-width: 900px;
    }
    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }
  &__form {
    display: grid;
    grid-template: 1fr / repeat(3, 356px);
    margin-bottom: 142px;
    gap: 16px 52px;
    @media (max-width: 1240px) {
      grid-template: 1fr / repeat(2, 1fr);
      justify-content: center;
    }
    @media (max-width: 991px) {
      margin-bottom: 120px;
    }
    @media (max-width: 767px) {
      grid-template: 1fr / 1fr;
      margin-left: auto;
      margin-right: auto;
      max-width: 370px;
      margin-bottom: 100px;
    }
    @media (max-width: 374px) {
      margin-bottom: 85px;
    }
    &-label {
      display: block;
      margin-bottom: 8px;
      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
    &-value {
      display: block;
      border: 1px solid #ced4da;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 7px 13px;
      width: 100%;
    }
  }
}
</style>
